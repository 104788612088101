import React from "react";
import {Box, Stack, useTheme} from "@mui/material";
import {useMediaQuery} from "@material-ui/core";
import logoBolton from "../assets/images/logo-bolton.png"

const LogoBox = ({logo}) => {
    const theme = useTheme();
    const matches = useMediaQuery('(max-height:680px)');

    const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });

    return(
        <Stack mt={2} alignItems={'center'} spacing={3}>
            <img style={{
                width: matches ? '150px' : '190px',
                height: '100%',
                objectFit:'contain'
            }} src={logoBolton}/>
            <img style={{
                    width: matches ? '50%' : '70%',
                    height: '100%',
                    objectFit:'contain'
                }} src={logo}/>
        </Stack>
    )
}

export default LogoBox
