import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import pleshDark from "../theme/pleshDark";

const PhotoGallery = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/gallery')
    }, [])

    return (<></>)
}

export default PhotoGallery